.slider {
  background: white;
  height: 290px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 1340px;
}

.slider .slide img {
  width: 246px;
  border-radius: 6px;
  height: 270px;
  object-fit: cover;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 36px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  animation: scroll 80s linear infinite;
  display: flex;
  width: calc(250px * 18);
}

.slider .slide-track:hover {
  animation-play-state: paused;
}

.slider .slide {
  height: 250px;
  width: 250px;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-250px * 9));
  }
}
