.wavefooterbaixo {
  margin-bottom: -20px;
  z-index: 22;
}

section#footer {
  background: #1f2631;
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  row-gap: 2rem;
  column-gap: 20rem;
}

.footer p {
  color: #fff;
}

.footer h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.footer-box p.u-text-small {
  display: flex;
  padding-top: 12px;
  padding-left: 20px;
  align-items: center;
  text-align: center;
}

.footer-fim {
  padding-top: 4px;
  padding-left: 140px;
  padding-right: 140px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  height: 8vh;
}

.footer-fim .footer-fim-esquerda {
  padding-top: 10px;
  font-size: 12px;
  color: black;
}

.footer-fim .footer-fim-direita {
  padding-top: 10px;
  font-size: 12px;
  color: black;
}

.footer-fim .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-fim .social-icon {
  /* margin: 2rem; */
  width: 4rem;
  height: 2rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}
