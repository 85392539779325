#about-section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 11%;
}

#about-section img {
  width: 400px;
  height: 460px;
  border-radius: 10px;
}

.about-right h4 {
  font-size: 18px;
}

.about-right {
  width: 57%;
  text-align: justify;
}
.about-right ul li {
  display: flex;
  align-items: center;
}
.about-right h1 {
  color: #f48213;
  font-size: 37px;
  margin-bottom: 5px;
  align-items: center;
}
.about-right p {
  color: black;
  line-height: 26px;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.about-right .address {
  margin: 25px 0;
}
.about-right .address ul li {
  margin-bottom: 5px;
}
.address .address-logo {
  margin-right: 15px;
  color: #e74d06;
}
.address .saprater {
  margin: 0 35px;
}
.about-right .expertise ul {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.expertise h3 {
  margin-bottom: 10px;
}
.expertise .expertise-logo {
  font-size: 19px;
  margin-right: 10px;
  color: #e74d06;
}
