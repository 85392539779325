@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
:root {
  --font-family: "DM Sans", sans-serif, serif;
  --dark-blue: #0A0C10;
  --light-blue: #1f93ff;
  --color-white: #fff;
  --dark-text: #0A0C10;
  --form-button: #2da5ff;
  --color-grey: #eae6eb;
  --box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}

body {
  font-family: "Poppins", sans-serif;
}

section {
  width: 100%;
  padding: 7rem 0;
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 20px;
}

/* Text styles */
.u-text-small {
  font-size: 13px;
  color: black;
  line-height: 1.4;
  font-weight: 300;
}

.u-text-small-bold h1 {
  font-size: 13px;
  font-weight: bold;
  color: black;
  line-height: 1.4;
}

.u-text-dark {
  color: black;
}
.u-text-light {
  color: black;
}

.u-text-center {
  text-align: center;
}

/* Title */
.u-title {
  text-align: center;
  margin-bottom: 4rem;
}

.u-title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: black;
  margin: 1.5rem 0;
}

.u-title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: #0A0C10;
  color: var(--dark-text);
}

.header-bg1 {
  width: 100%;
  height: 100%;
  background: #000000;
}

.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.home-titiles h1 {
  color: #f48213;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.home-titiles h2 {
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.home-titiles hr {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

a {
  font-size: 16px;
  font-weight: 300;
  color: #fff;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
a:hover,
#nav-links-mobile a:hover {
  color: #F48124;
}
a.btn {
  border-radius: 5px;
  padding: 5px 8px;
  font-weight: 500;
  cursor: pointer;
}

a.btn-dark {
  background: transparent;
  border: 2px solid #fff;
}
a.btn-light {
  background: #F48124;
  border: 2px solid transparent;
  color: #fff;
}

@media screen and (max-width: 600px) {
  a.btn {
    display: inline;
  }
}

.fixar {
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
  z-index: 30;
  border: 1px solid #dfe5e7;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 7rem;
  color: #000000;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-text {
  font-size: 40px;
  font-weight: 300;
  color: white;
}

.logo-text img {
  padding-top: 10px;
  height: 70px;
  width: 100%;
  object-fit: cover;
}

.logo-text span {
  font-weight: 600;
  color: #f48124;
}

.nav-links {
  display: flex;
  color: black;
}

.nav-links li:hover {
  border-bottom: 3px solid #f48213;
}

.nav-links li.active {
  border-bottom: 3px solid #f48213;
}

.nav-links li {
  margin-left: 10px;
  list-style: none;
  font-weight: bold;
}

.nav-links a {
  font-size: 16px;
  font-weight: 450;
  color: black;
  text-decoration: none;
  margin-right: 1rem;
  transition: all 0.3s;
}
.nav-links a:hover,
#nav-links-mobile a:hover {
  color: #f48124;
}

.navbar .menu-icons {
  background: transparent;
  border: none;
  border-radius: 3px;
  display: none;
}

/* Media Queries */
@media screen and (max-width: 769px) {
  /* hide nav button on navbar */
  .nav-btn {
    display: none;
  }
}
@media screen and (max-width: 600px) {
  /* show nav button on navbar menu */
  .nav-btn {
    display: block;
  }

  .nav-links {
    display: none;
    /* border: 2px solid #fff; */
    border: black;
    z-index: 111;
  }

  .navbar .menu-icons {
    display: block;
    background: #13c9f9;
  }

  .navbar #nav-links-mobile,
  .navbar #nav-links-mobile-hide {
    display: block;
    position: absolute;
    list-style: none;
    background: white;
    left: 0;
    top: 70px;
    width: 100%;
    transition: all 0.3s;
    /* border-top: 1px solid #fff; */
    border-top: 1px solid black;
    padding-bottom: 5px;
  }

  .navbar #nav-links-mobile {
    -webkit-animation: slide-in 0.5s ease-in-out;
            animation: slide-in 0.5s ease-in-out;
  }

  .navbar #nav-links-mobile-hide {
    left: -100%;
    -webkit-animation: slide-out 0.5s ease-in-out;
            animation: slide-out 0.5s ease-in-out;
  }
  @-webkit-keyframes slide-out {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }
  @keyframes slide-out {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    100% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
  }

  @-webkit-keyframes slide-in {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  @keyframes slide-in {
    0% {
      -webkit-transform: translateX(-100%);
              transform: translateX(-100%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  .navbar #nav-links-mobile li,
  .navbar #nav-links-mobile-hide li {
    text-align: center;
    padding: 10px 0;
    /* border-bottom: 1px solid #fff; */
    border-bottom: 1px solid black;
    margin: 0 30px;
  }

  .navbar #nav-links-mobile li:last-child {
    border-bottom: none;
  }

  .navbar #nav-links-mobile li a {
    /* color: #fff; */
    color: black;
    text-decoration: none;
    font-size: 18px;
  }

  .navbar #nav-links-mobile a:hover {
    color: #f48124;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}
.floating-icon-header1 {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-icon-header1 a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.title {
  text-align: center;
  margin-bottom: 4rem;
}

.title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}

.features-content {
  display: flex;
  justify-content: center;
  /* padding: 3rem 0; */
}

.features-left {
  flex: 1 1;
  text-align: center;
}
.features-left img {
  width: 51%;
  height: 88%;
  object-fit: contain;
}
.features-left .caracteristicas img {
  width: 81%;
  height: 100%;
}

.features-left .caracteristicas-condometro {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.features-left .caracteristicas-condometro img {
  width: 51%;
  height: 78%;
  object-fit: contain;
}

.features-left .caracteristicas-atendimentos img {
  width: 91%;
  height: 100%;
  object-fit: contain;
}

.features-right {
  flex: 1 1;
}

@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}

.feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 2rem;
}

.feature-icon {
  position: relative;
  margin-right: 2rem;
}

.inner-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.feature-text h3 {
  font-size: 2rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--light-blue);
}

section#download {
  background: linear-gradient(
    90deg,
    rgba(45, 165, 255, 1) 45%,
    rgba(1, 223, 245, 1) 100%
  );
  text-align: center;
  color: #fff;
}

.container .u-text-small-donload {
  color: white;
}

.download h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  position: relative;
  padding-bottom: 1rem;
}

.download h2::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 10%;
  height: 1px;
  background: var(--color-white);
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.download-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem;
}

.download-icon {
  margin: 1rem 2rem;
  border: 2px solid #fff;
  border-radius: 5px;
  width: 12rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  font-size: 1.5rem;
}

section#subscribe {
  background: url(/static/media/subscribe-bg.2f166fa2.png);
  text-align: center;
  color: #fff;
  background-size: cover;
  background-attachment: fixed;
}

.subscribe h2 {
  font-size: 3rem;
  font-weight: 200;
  line-height: 1.4;
  text-transform: uppercase;
  margin: 1.5rem 0;
  padding-bottom: 1rem;
}

form > * {
  width: 100%;
  max-width: 50rem;
  margin: 3rem auto;
}

form input {
  padding: 1rem;
  background: transparent;
  border: 1px solid #777;
  border-radius: 5px 0 0 5px;
  color: #fff;
  outline: none;
  width: 80%;
  font-size: 1.6rem;
}

form button {
  padding: 1rem;
  background: var(--form-button);
  border: 1px solid var(--form-button);
  border-radius: 0 5px 5px 0;
  color: #fff;
  font-size: 1.6rem;
  font-weight: 500;
}

.social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  margin: 2rem;
  padding: 1rem;
  width: 4rem;
  height: 4rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  cursor: pointer;
  transition: all 0.3s;
}

.social-icon:hover {
  background: var(--light-blue);
}

@media screen and (max-width: 600px) {
  form input {
    width: 60%;
  }
}

.question {
  border: 1px solid var(--light-blue);
  border-radius: 3px;
  width: 90%;
  max-width: 60rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  box-shadow: var(--box-shadow);
}

.question-title {
  display: flex;
  justify-content: space-between;
  align-items: center;

  /* padding-bottom: 1rem; */
}

.question-title h4 {
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.4;
}

button.question-icons {
  border: 1px solid var(--color-grey);
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.wavefooterbaixo {
  margin-bottom: -20px;
  z-index: 22;
}

section#footer {
  background: #1f2631;
  color: #fff;
}

.footer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  grid-row-gap: 2rem;
  row-gap: 2rem;
  grid-column-gap: 20rem;
  -webkit-column-gap: 20rem;
          column-gap: 20rem;
}

.footer p {
  color: #fff;
}

.footer h4 {
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.4;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.footer-box .footer-links a {
  font-size: 1.2rem;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-contact p {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.footer-box p.u-text-small {
  display: flex;
  padding-top: 12px;
  padding-left: 20px;
  align-items: center;
  text-align: center;
}

.footer-fim {
  padding-top: 4px;
  padding-left: 140px;
  padding-right: 140px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0px;
  height: 8vh;
}

.footer-fim .footer-fim-esquerda {
  padding-top: 10px;
  font-size: 12px;
  color: black;
}

.footer-fim .footer-fim-direita {
  padding-top: 10px;
  font-size: 12px;
  color: black;
}

.footer-fim .social-icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-fim .social-icon {
  /* margin: 2rem; */
  width: 4rem;
  height: 2rem;
  border: 1px solid #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s;
}

#header {
  position: relative;
}
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}
.parceiros {
  text-align: center;
  color: black;
}

.parceiros h1 {
  font-weight: bold;
  font-size: 38px;
  padding-top: 1px;
  color: #f48213;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.parceiros p {
  padding-bottom: 88px;
  padding-top: 8px;
  font-size: 16px;
  color: black;

  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.parceiros img {
  height: 160px;
  width: 400px;
  object-fit: scale-down;
}

.parceiros {
  color: #000;
}

.rec.rec-arrow {
  border-radius: 0;
  color: #000;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
  color: #000;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px #ffa500;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 0.75rem;
}
.section {
  width: 90vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -430px;
  z-index: 500000;
  max-width: 970px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  -webkit-transform: translateX(0);
          transform: translateX(0);
}
article.lastSlide {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
article.nextSlide {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: #f48012;
  margin-bottom: 1.25rem;
  font-size: 18px;
}

article .texto {
  font-size: 13px;
  color: black;
  line-height: 2;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: #0babe7;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  line-height: 1;
  color: hsl(210, 22%, 49%);
}

.prev,
.next {
  position: absolute;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  background: #fff;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.prev:hover,
.next:hover {
  background: hsl(21, 62%, 45%);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}

.footer-card {
  background-color: #0a1930;
}

.box img {
  width: 88px;
  height: 120px;
  padding-top: 26px;
  padding-bottom: 18px;
}

.whatsapp {
  padding-top: 1px;
}

.whatsapp img {
  object-fit: cover;
  width: 280px;
  height: 100px;
  cursor: pointer;
}

.header-bg1 {
  width: 100%;
  height: 100%;
  background: #0a1930;
}
.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.titulo-productos p {
  color: black;
  font-weight: 700;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 28px;
  align-items: center;
  text-align: center;
  line-height: 32px;
  padding-top: 1px;
  margin: 0 0 24px;
  padding-bottom: 50px;
}

.imagens-products img {
  height: 200px;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.headerr {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 100px;
  padding-bottom: 130px;
}

.headerr h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: black;
  position: relative;
  padding-bottom: 2rem;
}

.headerr h1 span {
  display: block;
  color: black;
}

.headerr h2 {
  padding-top: 24px;
  font-size: 29px;
  color: black;
}

.headerr .descri p {
  color: black;
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.headerr h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--dark-blue);
}

.header-left p {
  margin: 3rem 0;
  color: var(--dark-blue);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}
.floating-icon-header1 {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-icon-header1 a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .headerr h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .headerr {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .headerr h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.branding {
  background-color: #0a1930;
  color: #fff;
  padding: 80px 0;
}

.branding h1 {
  font-size: 40px;
  color: #f38012;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}

.branding h2 {
  font-weight: 500;
  font-size: 22px;
  margin: 10px 0 20px 0;
  align-items: center;
  text-align: center;
}

.area h1 {
  font-size: 22px;
  align-items: center;
  text-align: center;
}

.about-section-products {
  background: url(/static/media/Tw3.c449e84b.jpeg) no-repeat left;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  padding: 100px 0;
}

.inner-container {
  width: 55%;
  float: right;
  background-color: #f0f0f0;
  padding: 150px;
}

.inner-container ul {
  padding-left: 20px;
}

.u-text-small-cursos li {
  color: black;
  font-size: 16px;
  font-weight: 300;
}

.inner-container h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.text {
  font-size: 19px;
  color: black;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
}

.skills {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .inner-container {
    padding: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .about-section-products {
    background-size: 100%;
    padding: 100px 40px;
  }
  .inner-container {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about-section-products {
    padding: 0;
  }
  .inner-container {
    padding: 60px;
  }
}

:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: #fff;
    background-color: var(--primary);
    color: #242424;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid #fff;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
  
video {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
}

.hero-container {
  background: url(/static/media/H1.f8139a6c.jpeg) center center/cover
    no-repeat;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 160px;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  object-fit: cover;
}

.hero-container > h1 {
  color: #fff;
  font-size: 80px;
  margin-top: -100px;
}

.hero-container > p {
  margin-top: 8px;
  color: #fff;
  font-size: 28px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.hero-btns {
  margin-top: 32px;
}

.hero-btns .btn {
  margin: 6px;
}

.fa-play-circle {
  margin-left: 4px;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 70px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: -100px;
  }

  .hero-container > p {
    font-size: 30px;
  }

  .btn-mobile {
    display: block;
    text-decoration: none;
  }

  .btn {
    width: 100%;
  }
}

#about-section {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 80px 11%;
}

#about-section img {
  width: 400px;
  height: 460px;
  border-radius: 10px;
}

.about-right h4 {
  font-size: 18px;
}

.about-right {
  width: 57%;
  text-align: justify;
}
.about-right ul li {
  display: flex;
  align-items: center;
}
.about-right h1 {
  color: #f48213;
  font-size: 37px;
  margin-bottom: 5px;
  align-items: center;
}
.about-right p {
  color: black;
  line-height: 26px;
  font-size: 15px;
  padding-top: 12px;
  padding-bottom: 12px;
}
.about-right .address {
  margin: 25px 0;
}
.about-right .address ul li {
  margin-bottom: 5px;
}
.address .address-logo {
  margin-right: 15px;
  color: #e74d06;
}
.address .saprater {
  margin: 0 35px;
}
.about-right .expertise ul {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.expertise h3 {
  margin-bottom: 10px;
}
.expertise .expertise-logo {
  font-size: 19px;
  margin-right: 10px;
  color: #e74d06;
}

.slider {
  background: white;
  height: 290px;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 1340px;
}

.slider .slide img {
  width: 246px;
  border-radius: 6px;
  height: 270px;
  object-fit: cover;
}

.slider::before,
.slider::after {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
  content: "";
  height: 100%;
  position: absolute;
  width: 36px;
  z-index: 2;
}

.slider::after {
  right: 0;
  top: 0;
  -webkit-transform: rotateZ(180deg);
  transform: rotateZ(180deg);
}

.slider::before {
  left: 0;
  top: 0;
}

.slider .slide-track {
  -webkit-animation: scroll 80s linear infinite;
          animation: scroll 80s linear infinite;
  display: flex;
  width: calc(250px * 18);
}

.slider .slide-track:hover {
  -webkit-animation-play-state: paused;
          animation-play-state: paused;
}

.slider .slide {
  height: 250px;
  width: 250px;
}

@-webkit-keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 9));
            transform: translateX(calc(-250px * 9));
  }
}

@keyframes scroll {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-250px * 9));
            transform: translateX(calc(-250px * 9));
  }
}

.header-bg1 {
  width: 100%;
  height: 100%;
  background: #0a1930;
}
.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

/*CSS do Youtube*/
.video-title h1 {
  text-align: center;
  color: black;
  font-size: 30px;
  padding-bottom: 12px;
}

.video-title h2 {
  text-align: center;
  font-weight: bolder;
  color: black;
  padding-top: 22px;
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* - 16:9 aspect ratio (most common) */
  /* padding-bottom: 62.5%; - 16:10 aspect ratio */
  /* padding-bottom: 75%; - 4:3 aspect ratio */
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.embed-youtube iframe,
.embed-youtube object,
.embed-youtube embed {
  padding-top: 60px;
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  padding-left: 100px;
  padding-right: 10px;
  width: 90%;
  height: 78%;
}

.about-video p {
  text-align: center;
  font-size: 16px;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.header-bg1 {
    width: 100%;
    height: 100%;
    background: #0a1930;
  }
  .header-bg2 {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #0a1930;
  }
 
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

.contact-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
	width: 80%;
	height: auto;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
}

.contact-in {
	padding: 40px 30px;
}

.contact-in:nth-child(1) {
	flex: 30% 1;
	background: url(/static/media/1.81c82c1a.jpg);
	color: #fff;
}

.contact-in:nth-child(2) {
	flex: 45% 1;
	background: #F48213;
}

.contact-in:nth-child(3) {
	flex: 25% 1;
	padding: 0;
}

.contact-in h1 {
	font-size: 24px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 20px;
}

.contact-in h2 {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 15px;
}

.contact-in h2 i {
	font-size: 16px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	background: #f5f5f5;
	color: #000;
	border-radius: 50px;
	line-height: 40px;
	text-align: center;
}

.contact-in p {
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 20px;
}

.contact-in ul {
	padding: 0;
	margin: 0;
}

.contact-in ul li {
	list-style: none;
	display: inline-block;
	margin-right: 5px;
	margin-top: 5px;
}

.contact-in ul li a {
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50px;
}

.contact-in ul li a i {
	font-size: 14px;
	line-height: 30px;
	color: #000;
}

.contact-in form {
	width: 100%;
	height: auto;
}

.contact-in-input {
	width: 100%;
	height: 40px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	outline: none;
	padding-left: 5px;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
}

.contact-in-input::-webkit-input-placeholder {
	color: #fff;
}

.contact-in-input:-ms-input-placeholder {
	color: #fff;
}

.contact-in-input::placeholder {
	color: #fff;
}

.contact-in-textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	outline: none;
	padding-top: 5px;
	padding-left: 5px;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
}

.contact-in-textarea::-webkit-input-placeholder {
	color: #fff;
}

.contact-in-textarea:-ms-input-placeholder {
	color: #fff;
}

.contact-in-textarea::placeholder {
	color: #fff;
}

.contact-in-btn {
	width: 100%;
	height: 40px;
	border: 1px solid #fff;
	outline: none;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}

.contact-in iframe {
	width: 100%;
	height: 100%;
}

@media only screen and (max-width:480px) {
	.contact-in:nth-child(1) {
		flex: 50% 1;
	}

	.contact-in:nth-child(2) {
		flex: 50% 1;
	}

	.contact-in:nth-child(3) {
		flex: 100% 1;
	}

}

@media only screen and (max-width:360px) {
	.contact-in:nth-child(1) {
		flex: 50% 1;
	}

	.contact-in:nth-child(2) {
		flex: 50% 1;
	}

	.contact-in:nth-child(3) {
		flex: 100% 1;
	}

}


@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}


@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.about-section {
  background: url(/static/media/capacete.853b6bc1.jpg) no-repeat left;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  padding: 100px 0;
}

.about-section2 {
  background: url(/static/media/boats.e0654ddc.png) no-repeat right;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  padding: 100px 0;
}

.inner-container {
  width: 55%;
  border-radius: 150px;
  float: right;
  background-color: #f0f0f0;
  padding: 140px;
}

.inner-container2 {
  width: 55%;
  border-radius: 150px;
  float: left;
  background-color: #f0f0f0;
  padding: 140px;
}

.inner-container ul {
  padding-left: 20px;
}

.inner-container2 ul {
  padding-left: 20px;
}

.u-text-small-cursos li {
  color: black;
  font-size: 16px;
  font-weight: 300;
}

.inner-container h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.inner-container2 h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.text {
  font-size: 19px;
  color: black;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
}

.skills {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .inner-container {
    padding: 80px;
  }

  .inner-container2 {
    padding: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .about-section {
    background-size: 100%;
    padding: 100px 40px;
  }

  .inner-container {
    width: 100%;
  }

  .about-section2 {
    background-size: 100%;
    padding: 100px 40px;
  }

  .inner-container2 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about-section {
    padding: 0;
  }

  .inner-container {
    padding: 60px;
  }

  .about-section2 {
    padding: 0;
  }

  .inner-container2 {
    padding: 60px;
  }
}

.slogan-geral {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -10px;
  height: 18vh;
  background-color: #f48213;
  color: white;
  box-sizing: border-box;
}

.wavebaixo {
  margin-top: -40px;
}

.slogan-geral .cima p {
  font-size: 38px;
  font-weight: 300;
  font-size: 24px;
  font-weight: bold;
  list-style-type: none;
}

.slogan-geral .baixo p {
  color: white;
  padding-top: 1px;
  font-weight: 300;
  font-size: 28px;
  list-style-type: none;
  z-index: 500;
}

.wave{
    z-index: 200;
}
.header-bg1 {
  width: 100%;
  height: 100%;
  background: #0a1930;
}

.distancia {
  padding-top: 1px;
}
.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

.video-title hr {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
}

.title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}

.features-content {
  display: flex;
  justify-content: center;
  /* padding: 3rem 0; */
}

.features-left {
  flex: 1 1;
  text-align: center;
}
.features-left img {
  width: 51%;
  height: 88%;
  object-fit: contain;
}
.features-left .caracteristicas img {
  width: 81%;
  height: 100%;
}

.features-left .caracteristicas-condometro {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.features-left .caracteristicas-condometro img {
  width: 51%;
  height: 78%;
  object-fit: contain;
}

.features-left .caracteristicas-treinamento img {
  width: 100%;
  height: 50%;
  object-fit: contain;
}

.features-right {
  flex: 1 1;
}

@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.headerr {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 50vh;
}

.headerr h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: black;
  position: relative;
  padding-bottom: 2rem;
}

.headerr h1 span {
  display: block;
  color: black;
}

.headerr h2 {
  padding-top: 24px;
  font-size: 29px;
  color: black;
}

.headerr .descri p {
  color: black;
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.headerr h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--dark-blue);
}

.header-left p {
  margin: 3rem 0;
  color: var(--dark-blue);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}
.floating-icon-header1 {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-icon-header1 a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .headerr h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .headerr {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .headerr h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

.header-bg1 {
    width: 100%;
    height: 100%;
    background: #0a1930;
  }
  .header-bg2 {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #0a1930;
  }


.horizontal-services {
    color: white;
    box-sizing: border-box;
    height: 20vh;
    background-color: #ffa500;
    display: flex;
    justify-content: center;
    padding-bottom: 28px;
    padding-top: 28px;
}

.horizontal-services{
    color: #fff;
    box-sizing: border-box;
    height: 20vh;
    background-color: orange;
    display: flex;
    justify-content: center;
    padding-bottom: 28px;
    padding-top: 28px
}

.horizontal-services h3{
    padding-top: 20px;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5;
}

.horizontal-services-cursos h3{
    padding-top: 50px;
    color: #F47419;
    text-align: center;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}


.horizontal-services-cursos h1{
    padding-top: 20px;
    padding-bottom: 26px;
    color: black;
    text-align: center;
    font-size: 32px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}
  
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  -webkit-transform: translateY(-25%);
          transform: translateY(-25%);
  -webkit-animation: bounce 0.8s ease infinite alternate;
          animation: bounce 0.8s ease infinite alternate;
}

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@keyframes bounce {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(25%);
            transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}

