#header {
  position: relative;
}
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}
.parceiros {
  text-align: center;
  color: black;
}

.parceiros h1 {
  font-weight: bold;
  font-size: 38px;
  padding-top: 1px;
  color: #f48213;
  font-size: 24px;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.parceiros p {
  padding-bottom: 88px;
  padding-top: 8px;
  font-size: 16px;
  color: black;

  font-size: 14px;
  font-weight: 600;
  align-items: center;
  text-align: center;
}

.parceiros img {
  height: 160px;
  width: 400px;
  object-fit: scale-down;
}

.parceiros {
  color: #000;
}

.rec.rec-arrow {
  border-radius: 0;
  color: #000;
}
/* round buttons on hover */
.rec.rec-arrow:hover {
  border-radius: 50%;
  color: #000;
}
/* hide disabled buttons */
.rec.rec-arrow:disabled {
  visibility: hidden;
}
/* disable default outline on focused items */
/* add custom outline on focused items */
.rec-carousel-item:focus {
  outline: none;
  box-shadow: inset 0 0 1px 1px #ffa500;
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}
