.header-bg1 {
  width: 100%;
  height: 100%;
  background: #0a1930;
}

.distancia {
  padding-top: 1px;
}
.header-bg2 {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0a1930;
}

.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  transform: translateY(-25%);
  animation: bounce 0.8s ease infinite alternate;
}

.video-title hr {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}
.title {
  text-align: center;
  margin-bottom: 4rem;
}

.title h2 {
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.4;
  text-transform: uppercase;
  color: var(--dark-text);
  margin: 1.5rem 0;
}

.title p {
  width: 50%;
  min-width: 40rem;
  margin: 0 auto;
  color: var(--dark-text);
}

.features-content {
  display: flex;
  justify-content: center;
  /* padding: 3rem 0; */
}

.features-left {
  flex: 1;
  text-align: center;
}
.features-left img {
  width: 51%;
  height: 88%;
  object-fit: contain;
}
.features-left .caracteristicas img {
  width: 81%;
  height: 100%;
}

.features-left .caracteristicas-condometro {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.features-left .caracteristicas-condometro img {
  width: 51%;
  height: 78%;
  object-fit: contain;
}

.features-left .caracteristicas-treinamento img {
  width: 100%;
  height: 50%;
  object-fit: contain;
}

.features-right {
  flex: 1;
}

@media screen and (max-width: 600px) {
  .features-content {
    flex-direction: column;
  }
}

@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}
