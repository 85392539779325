h1,
h2,
h3,
h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1;
  margin-bottom: 0.75rem;
}
.section {
  width: 90vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -430px;
  z-index: 500000;
  max-width: 970px;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;
  width: 80vw;
  height: 450px;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}
article.lastSlide {
  transform: translateX(-100%);
}
article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: #f48012;
  margin-bottom: 1.25rem;
  font-size: 18px;
}

article .texto {
  font-size: 13px;
  color: black;
  line-height: 2;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.75rem;
  color: #0babe7;
}

.title {
  text-align: center;
  margin-bottom: 2rem;
}
.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  line-height: 1;
  color: hsl(210, 22%, 49%);
}

.prev,
.next {
  position: absolute;
  transform: translateY(-50%);
  background: #fff;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}
.prev:hover,
.next:hover {
  background: hsl(21, 62%, 45%);
}
.prev {
  left: 0;
}
.next {
  right: 0;
}
@media (min-width: 800px) {
  .text {
    max-width: 45em;
  }
  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
  }
}
