.about-section {
  background: url(../../assets/Products/capacete.jpg) no-repeat left;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  padding: 100px 0;
}

.about-section2 {
  background: url(../../assets/Products/boats.png) no-repeat right;
  background-size: 55%;
  background-color: #fdfdfd;
  overflow: hidden;
  padding: 100px 0;
}

.inner-container {
  width: 55%;
  border-radius: 150px;
  float: right;
  background-color: #f0f0f0;
  padding: 140px;
}

.inner-container2 {
  width: 55%;
  border-radius: 150px;
  float: left;
  background-color: #f0f0f0;
  padding: 140px;
}

.inner-container ul {
  padding-left: 20px;
}

.inner-container2 ul {
  padding-left: 20px;
}

.u-text-small-cursos li {
  color: black;
  font-size: 16px;
  font-weight: 300;
}

.inner-container h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.inner-container2 h1 {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 900;
  color: black;
}

.text {
  font-size: 19px;
  color: black;
  line-height: 30px;
  text-align: justify;
  margin-bottom: 40px;
}

.skills {
  display: flex;
  justify-content: space-between;
  font-weight: 700;
  font-size: 13px;
}

@media screen and (max-width: 1200px) {
  .inner-container {
    padding: 80px;
  }

  .inner-container2 {
    padding: 80px;
  }
}

@media screen and (max-width: 1000px) {
  .about-section {
    background-size: 100%;
    padding: 100px 40px;
  }

  .inner-container {
    width: 100%;
  }

  .about-section2 {
    background-size: 100%;
    padding: 100px 40px;
  }

  .inner-container2 {
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .about-section {
    padding: 0;
  }

  .inner-container {
    padding: 60px;
  }

  .about-section2 {
    padding: 0;
  }

  .inner-container2 {
    padding: 60px;
  }
}
