.header-bg1 {
    width: 100%;
    height: 100%;
    background: #0a1930;
  }
  .header-bg2 {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background: #0a1930;
  }
 
.header {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.header h1 {
  font-size: 4rem;
  line-height: 1.4;
  color: var(--color-white);
  position: relative;
  padding-bottom: 2rem;
}

.header h1 span {
  display: block;
}

.header h2 span {
  padding-top: 24px;
  font-size: 29px;
  color: var(--color-white);
}

h1 span:nth-child(1) {
  font-weight: 800;
}

h1 span:nth-child(2),
h1 span:nth-child(3) {
  font-weight: 100;
}

.header h1::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 75%;
  height: 1px;
  background: var(--color-white);
}

.header-left p {
  margin: 3rem 0;
  color: var(--color-white);
  width: 70%;
}

.header-right img {
  width: 90%;
}

.floating-icon {
  background-color: var(--light-blue);
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 100%;
  bottom: 0;
  transform: translateX(-50%);
  cursor: pointer;
}

.floating-icon a {
  border: 1px solid #fff;
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mouse {
  transform: translateY(-25%);
  animation: bounce 0.8s ease infinite alternate;
}

.contact-wrap {
  padding-top: 100px;
  padding-bottom: 100px;
	width: 80%;
	height: auto;
	margin: auto;
	display: flex;
	flex-wrap: wrap;
}

.contact-in {
	padding: 40px 30px;
}

.contact-in:nth-child(1) {
	flex: 30%;
	background: url(../../../assets/Contactus/1.jpg);
	color: #fff;
}

.contact-in:nth-child(2) {
	flex: 45%;
	background: #F48213;
}

.contact-in:nth-child(3) {
	flex: 25%;
	padding: 0;
}

.contact-in h1 {
	font-size: 24px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	margin-bottom: 20px;
}

.contact-in h2 {
	font-size: 20px;
	font-weight: 400;
	margin-bottom: 15px;
}

.contact-in h2 i {
	font-size: 16px;
	width: 40px;
	height: 40px;
	margin-right: 10px;
	background: #f5f5f5;
	color: #000;
	border-radius: 50px;
	line-height: 40px;
	text-align: center;
}

.contact-in p {
	font-size: 14px;
	font-weight: 300;
	margin-bottom: 20px;
}

.contact-in ul {
	padding: 0;
	margin: 0;
}

.contact-in ul li {
	list-style: none;
	display: inline-block;
	margin-right: 5px;
	margin-top: 5px;
}

.contact-in ul li a {
	display: block;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 50px;
}

.contact-in ul li a i {
	font-size: 14px;
	line-height: 30px;
	color: #000;
}

.contact-in form {
	width: 100%;
	height: auto;
}

.contact-in-input {
	width: 100%;
	height: 40px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	outline: none;
	padding-left: 5px;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
}

.contact-in-input::placeholder {
	color: #fff;
}

.contact-in-textarea {
	width: 100%;
	height: 140px;
	margin-bottom: 20px;
	border: 1px solid #fff;
	outline: none;
	padding-top: 5px;
	padding-left: 5px;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
}

.contact-in-textarea::placeholder {
	color: #fff;
}

.contact-in-btn {
	width: 100%;
	height: 40px;
	border: 1px solid #fff;
	outline: none;
	background: transparent;
	color: #fff;
	font-size: 12px;
	font-weight: 300;
	font-family: 'Poppins', sans-serif;
	cursor: pointer;
}

.contact-in iframe {
	width: 100%;
	height: 100%;
}

@media only screen and (max-width:480px) {
	.contact-in:nth-child(1) {
		flex: 50%;
	}

	.contact-in:nth-child(2) {
		flex: 50%;
	}

	.contact-in:nth-child(3) {
		flex: 100%;
	}

}

@media only screen and (max-width:360px) {
	.contact-in:nth-child(1) {
		flex: 50%;
	}

	.contact-in:nth-child(2) {
		flex: 50%;
	}

	.contact-in:nth-child(3) {
		flex: 100%;
	}

}


@keyframes bounce {
  0% {
    transform: translateY(-25%);
  }
  100% {
    transform: translateY(25%);
  }
}

@media screen and (max-width: 769px) {
  .header h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 600px) {
  .header {
    flex-direction: column;
  }

  .header-left,
  .header-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 auto;
  }

  .header-left {
    margin-bottom: 4rem;
  }

  .header h1::after {
    left: 50%;
    transform: translateX(-50%);
  }
  .header-right img {
    width: 80%;
  }
}
