.grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
}

.branding {
  background-color: #0a1930;
  color: #fff;
  padding: 80px 0;
}

.branding h1 {
  font-size: 40px;
  color: #f38012;
  align-items: center;
  text-align: center;
  margin-right: 20px;
}

.branding h2 {
  font-weight: 500;
  font-size: 22px;
  margin: 10px 0 20px 0;
  align-items: center;
  text-align: center;
}

.area h1 {
  font-size: 22px;
  align-items: center;
  text-align: center;
}
