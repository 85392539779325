.slogan-geral {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: -10px;
  height: 18vh;
  background-color: #f48213;
  color: white;
  box-sizing: border-box;
}

.wavebaixo {
  margin-top: -40px;
}

.slogan-geral .cima p {
  font-size: 38px;
  font-weight: 300;
  font-size: 24px;
  font-weight: bold;
  list-style-type: none;
}

.slogan-geral .baixo p {
  color: white;
  padding-top: 1px;
  font-weight: 300;
  font-size: 28px;
  list-style-type: none;
  z-index: 500;
}
